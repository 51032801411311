import React, { useRef, useEffect , useCallback} from "react"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers"
import InputMask from "react-input-mask"
import * as yup from "yup"
import axios from "axios"

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

interface IFormInputs {
  Name: string,
  Email: string,
  Phone: string
  Subject: string,
  Message: string,
}

const ContactForm: React.FC = () => {
    const backend = process.env.BACKEND_API

    type Inputs = {
      Name: string,
      Email: string,
      Subject: string,
      Message: string
      Phone: string
    }

    const formSchema = yup.object().shape({
        Name: yup.string().required("Preencha o campo"),
        Email: yup
            .string()
            .email("Digite um endereço de email válido")
            .required("Preencha o campo"),
        Phone: yup.string(),
        Subject: yup.string().required("Preencha o campo"),
        Message: yup.string().required("Preencha o campo"),
    })

    const { control, handleSubmit, errors } = useForm<Inputs>({
        resolver: yupResolver(formSchema),
    })

    const formRef = useRef<HTMLFormElement>(null);
    const btnSubmit = useRef()

    const delay = (ms) => new Promise(
      resolve => setTimeout(resolve, ms)
    );

    const success = (name: string) => {
      toast.success(name + ' seu email foi enviado com Sucesso !', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        containerId: 'success'
      });
    }

    const info = (name: string ) => {
      toast.info(name + ' seu email esta sendo enviado !', {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        containerId: 'info'
      });
    }

    const onSubmit = async (values: IFormInputs) => {

      btnSubmit.current.disabled = true

      let objSubmit = {
        Name: values.Name,
        Email: values.Email,
        Subject: values.Subject,
        Message: values.Message,
      }
      if (values.Phone) objSubmit.Phone = values.Phone

      axios
      .post('https://backend.gifthy.com.br/app/contact', objSubmit)
      .then(res => {
          btnSubmit.current.disabled = false;

          control.setValue("Name", "")
          control.setValue("Email", "")
          control.setValue("Subject", "")
          control.setValue("Message", "")
          control.setValue("Phone", "")

          info(objSubmit.Name);
      })
      .catch(err => {
        console.log("Error: ",  err)
      })

      await delay(3000);
      success(objSubmit.Name);
    }

    return (
        <section className="contact" id="contato">
            <h1 className="title blue-font font-bold">Entre em contato</h1>
            <h1 className="title yellow-font">
                O Gifthy agradece e responderá o mais rápido possível.
            </h1>
            <form ref={formRef} className="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="wrapper">
                    <div>
                        <label htmlFor="Name">Nome</label>
                        <Controller
                            as={<input />}
                            className="name"
                            placeholder="Seu nome"
                            name="Name"
                            control={control}
                            defaultValue=""
                        />
                        {errors.Name && (
                            <label className="error-label">
                                {errors.Name.message}
                            </label>
                        )}
                    </div>
                    <div>
                        <label htmlFor="Email">Email</label>
                        <Controller
                            as={<input />}
                            className="email"
                            placeholder="Seu email"
                            name="Email"
                            control={control}
                            defaultValue=""
                        />
                        {errors.Email && (
                            <label className="error-label">
                                {errors.Email.message}
                            </label>
                        )}
                    </div>
                    <div>
                        <label htmlFor="Phone">Telefone</label>
                        <Controller
                            as={
                                <InputMask
                                    mask="(99) 99999-9999"
                                    maskChar=" "
                                />
                            }
                            className="phone"
                            placeholder="Seu telefone"
                            name="Phone"
                            control={control}
                            defaultValue=""
                        />
                        {errors.Phone && (
                            <label className="error-label">
                                {errors.Phone.message}
                            </label>
                        )}
                    </div>
                    <div>
                        <label htmlFor="Subject">Assunto</label>
                        <Controller
                            as={<input />}
                            className="subject"
                            placeholder="Assunto mensagem"
                            name="Subject"
                            control={control}
                            defaultValue=""
                        />
                        {errors.Subject && (
                            <label className="error-label">
                                {errors.Subject.message}
                            </label>
                        )}
                    </div>
                    <div className="message-box">
                        <label htmlFor="Message">Mensagem</label>
                        <Controller
                            as={<textarea />}
                            className="message"
                            placeholder="Sua mensagem"
                            name="Message"
                            control={control}
                        />
                        {errors.Message && (
                            <label className="error-label">
                                {errors.Message.message}
                            </label>
                        )}
                    </div>
                </div>
                <button
                    className="submit-button white-font font-bold"
                    ref={btnSubmit}
                    type="submit"
                >
                    Enviar
                </button>
            </form>
            <ToastContainer
              enableMultiContainer containerId={'info'}
              position="bottom-center"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />         
            
            <ToastContainer
              enableMultiContainer containerId={'success'}
              position="bottom-center"
              autoClose={2000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />        
        </section>
    )
}

export default ContactForm
